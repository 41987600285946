<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 资讯列表 </template>
      <template #input>
        <span class="tag">发布时间: </span>
        <a-range-picker @change="onChange" />
        <a-input placeholder="请输入关键字" v-model="keyWord" allow-clear width="240" @change="onChangeKey" />
        <a-button type="primary" class="btn" @click="onSearch">搜索</a-button>
      </template>
    </HeaderBox>
    <div class="function">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="首页资讯"></a-tab-pane>
        <a-tab-pane key="2" tab="教资资讯"></a-tab-pane>
      </a-tabs>
      <div>
        <a-button class="all_boder_btn" @click="toDetail(1, type)">新建</a-button>
      </div>
    </div>

    <div class="table-box">
      <div class="table-left">
        <div class="item" v-for="item in categoryList" :key="item.newsTypeId">
          <p class="item-name" :class="{'item-name-active':item.isOpen}" @click="onCategory(item)">{{item.newsTypeName}}</p>
        </div>
      </div>

      <a-table
        class="table-right"
        rowKey="newsId"
        :loading="loading"
        :columns="columns"
        :data-source="tableData"
        @change="changePage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: (res) => `共${total}条`,
        }"
      >
        <template slot="index" slot-scope="item, row, index">
          {{ (pageNumber - 1) * pageSize + index + 1 }}
        </template>

        <template slot="createTime" slot-scope="item">
          {{ item.split(" ")[0] }}
        </template>

        <template slot="operation" slot-scope="item, row">
          <span class="btn_router_link">
            <span @click="toDetail(2, type, row.newsId)">编辑</span> |
            <span @click="deteData(row)">删除</span>
          </span>
        </template>
      </a-table>
    </div>

  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "标题",
    width: '350px',
    align: "center",
    ellipsis :'true',
    dataIndex: "title",
  },
  {
    title: "发布时间",
    align: "center",
    dataIndex: "createTime",
    scopedSlots: {
      customRender: "createTime",
    },
  },
  {
    title: "浏览量",
    align: "center",
    dataIndex: "views",
  },
  {
    title: "来源",
    align: "center",
    ellipsis :'true',
    dataIndex: "source",
  },
  {
    title: "操作",
    align: "center",
    width: '150px',
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      type: 1,
      categoryType: 1, 
      categoryList: [],
      newTypeList: [], //资讯列表

      loading: false,
      firstDate: "", //开始时间
      secondDate: "", //结束时间
      keyWord: "", //关键字

      columns,
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
    };
  }, // 事件处理器
  methods: {
    // 分页
    changePage(e) {
      // console.log(e);
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },

    // 搜索
    onSearch() {
      this.pageNumber=1;
      this.getData();// 资讯列表
    },

    // 日期
    onChange(date, dateString) {
      // console.log(dateString);
      this.firstDate = dateString[0];
      this.secondDate = dateString[1];
      if (this.firstDate=='' && this.secondDate=='') {
        // 资讯列表
        this.getData();
      }
    },
    // 关键字
    onChangeKey(e) {
      if (this.keyWord == '') {
        // 资讯列表
        this.getData();
      }
    },

    toDetail(operation, type, id) {
      this.$router.push(
        "/admin/contentConfig/infoDetail?" +
          "operation=" +
          operation +
          "&type=" +
          type +
          "&id=" +
          id
      );
    },

    // 删除
    deteData(item) {
      // console.log(item.newsId);
      let _this = this;
      this.$confirm({
        title: "确定删除该条内容吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/news/?newsId=" + item.newsId,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.getData();
                _this.$message.success("删除成功");
              }
            });
        },
        onCancel() {},
      });
    },

    callback(e){
      this.type = e;
      this.getCategory();
    },

    onCategory(item){
      this.categoryList.map(i=>{
        if(i.newsTypeId == this.categoryType){
          i.isOpen = false;
        }
      })
      this.categoryType = item.newsTypeId;
      item.isOpen = true;
      this.getData();
    },

    // 资讯分类
    getCategory() {
      this.$ajax({
        url: "/hxclass-management/news/type/" + this.type,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.categoryList = res.data.map((item,index)=>{
            return {
              ...item,
              isOpen: index === 0?true:false,
            }
          });
          this.categoryType = res.data[0].newsTypeId;
          this.getData();
        }
      });
    },

    // 资讯列表
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/news/",
        method: "GET",
        params: {
          current: this.pageNumber,
          firstDate: this.firstDate,
          secondDate: this.secondDate,
          keyWord: this.keyWord,
          newsTypeId: this.categoryType,
          size: this.pageSize,
        },
      }).then((res) => {
        this.tableData = res.data.records;
        // this.pageNumber = 1;
        this.total = res.data.total;
        this.loading = false;
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    // 资讯分类
    this.getCategory();
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.function{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /deep/ .ant-tabs-bar{
    border-bottom: none;
  }
  .all_boder_btn{
    margin-right: 20px;
  }
}
.table-box{
  .table-left{
    float: left;
    width: 170px;
    margin-right: 40px;
    border: 1px solid #EEEEEE;
    .item{
      border-bottom: 1px solid #EEEEEE;
      cursor: pointer;
      .item-name{
        font-size: 14px;
        color: #333333;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .item-name-active{
        color: #FFFFFF;
        background: #2B6ED4;
      }
    }
    .item:last-child{
      border-bottom:none
    }
    
  }
  .table-right{
    float: left;
    width: calc(100% - 210px);
  }
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
  /deep/.btn_router_link{
    cursor: pointer;
  }
}
.all_main_wrap {
  min-height: 100%;

  .btn {
    margin: 0 24px 14px 0;
  }
}
</style>
